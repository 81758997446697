import React from "react";
import loadable from "@loadable/component";
import { Section } from "@atoms";

const DefaultPageContainer = loadable(() =>
  import("@templates/DefaultPageContainer")
);
const Frame = loadable(() => import("@templates/Frame"));
const Hero = loadable(() => import("@organisms/Hero"));
const SectionIntro = loadable(() => import("@organisms/SectionIntro"));
const GrowingImageCopy = loadable(() => import("@templates/GrowingImageCopy"));
const GrowingImageSlide = loadable(() =>
  import("@templates/GrowingImageSlide")
);
const GrowingImageStories = loadable(() =>
  import("@templates/GrowingImageStories")
);
const Grants = loadable(() => import("@templates/Grants"));

const DreamPage = ({ data }) => {
  const {
    title,
    intro,
    grantmaking,
    map,
    world,
    activate,
    funds,
    stories,
    values,
    voices,
    power,
    philanthropy,
    feminism,
  } = data;

  return (
    <DefaultPageContainer>
      <Frame title={intro?.heading || title} previous="/can-you-build-it" />
      <Hero {...intro} largerHeadingSpace />
      <div className="relative z-10 my-12 border-b-3 border-white md:ml-14" />
      {/* grantmaking */}
      <Section id="grantmaking">
        <SectionIntro {...grantmaking} />
        <div className="relative z-10 my-12 border-b-3 border-white md:ml-14" />
        <GrowingImageCopy {...world} />
        <Grants world={world} map={map} />
        <div className="relative z-10 my-12 border-b-3 border-white md:ml-14" />
      </Section>
      {/* stories */}
      <Section id="activate">
        <SectionIntro {...activate} />
        <div className="relative z-10 my-12 border-b-3 border-white md:ml-14" />
        <GrowingImageSlide {...funds} headingAlign="center" />
      </Section>
      <Section id="stories">
        <div className="relative z-10 mb-12 border-t-3 border-white md:ml-14" />
        <SectionIntro {...stories} />
        <div className="relative z-10 my-12 ml-14 border-b-3 border-white" />
      </Section>
      <Section id="values-in-action">
        <GrowingImageStories {...values} headingAlign="center" />
      </Section>
      <Section id="wvl-caribbean">
        <SectionIntro {...voices} />
        <div className="relative z-10 my-12 border-b-3 border-white md:ml-14" />
        <GrowingImageSlide {...power} headingAlign="center" />
      </Section>
      {/* philanthropy */}
      <Section id="philanthropy">
        <SectionIntro {...philanthropy} />
        <div className="relative z-10 my-12 border-b-3 border-white md:ml-14" />
        <GrowingImageSlide {...feminism} headingAlign="center" />
      </Section>
    </DefaultPageContainer>
  );
};

DreamPage.defaultProps = {};

export default DreamPage;
