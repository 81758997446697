import React from "react";
import { graphql } from "gatsby";
import PageTemplate from "@pageTemplates/MoveIt";
import resolveSideScroll from "@dataResolvers/resolveSideScroll";
import resolveStory from "@dataResolvers/resolveStory";
import resolveImage from "@dataResolvers/resolveImage";
// eslint-disable-next-line no-unused-vars
import ImageFragment from "@fragments/ImageFragment";
// eslint-disable-next-line no-unused-vars
import StoriesFragment from "@fragments/StoriesFragment";
// eslint-disable-next-line no-unused-vars
import SideScrollFragment from "@fragments/SideScrollFragment";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query MoveItQuery($siteId: [Craft_QueryArgument]) {
    craft {
      entry(section: "canYouMoveIt", siteId: $siteId) {
        ... on Craft_canYouMoveIt_canYouMoveIt_Entry {
          title
          metaTitle
          metaDescription
          metaImage {
            ...ImageFragment
          }
          # intro
          introHeading: heading0
          introDescriptor: descriptor0
          # grantmaking
          grantmakingLabel: heading1
          grantmakingHeading: heading2
          grantmakingDescriptor: descriptor1
          # world
          wordlHeading: heading10
          worldCopy: copy0
          worldImage: image0 {
            ...ImageFragment
          }
          worldStats: stats {
            ... on Craft_stats_labelStatLabel_BlockType {
              type: typeHandle
              uid
              prefixLabel
              stat
              suffixLabel
            }
            ... on Craft_stats_statLabel_BlockType {
              type: typeHandle
              uid
              stat
              label
            }
            ... on Craft_stats_shortStats_BlockType {
              type: typeHandle
              uid
              stats {
                stat
                label
              }
            }
          }
          # map
          mapLabel: heading3
          mapHeading: heading11
          mapSubheading: descriptor2
          mapCopy: copy1
          # activate
          activateLabel: heading4
          activateHeading: heading5
          activateCopy: copy2
          # funds
          fundsHeading: heading12
          fundsSideScroll: sideScrollEmbed0 {
            ...SideScrollFragment
          }
          # stories
          storiesLabel: heading7
          storiesHeading: heading8
          storiesDescriptor: descriptor3
          # values
          valuesHeading: heading13
          valuesImage: image1 {
            ...ImageFragment
          }
          stories: storiesList {
            ...StoriesFragment
          }
          #voices
          voicesLabel: heading16
          voicesHeading: heading15
          voicesCopy: copy3
          #power
          powerHeading: heading14
          powerSideScroll: sideScrollEmbed1 {
            ...SideScrollFragment
          }
          #philanthropy
          philanthropyLabel: heading17
          philanthropyHeading: heading9
          philanthropyDescriptor: descriptor5
          #feminism
          feminismHeading: heading6
          feminismSideScroll: sideScrollEmbed2 {
            ...SideScrollFragment
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry }) => {
  const {
    title,
    // intro,
    introHeading,
    introDescriptor,
    // grantmaking,
    grantmakingLabel,
    grantmakingHeading,
    grantmakingDescriptor,
    // world,
    wordlHeading,
    worldCopy,
    worldImage,
    worldStats,
    // map,
    mapLabel,
    mapHeading,
    mapSubheading,
    mapCopy,
    // activate,
    activateLabel,
    activateHeading,
    activateCopy,
    // funds,
    fundsHeading,
    fundsSideScroll,
    // stories,
    storiesLabel,
    storiesHeading,
    storiesDescriptor,
    // values,
    valuesHeading,
    valuesImage,
    stories,
    // voices,
    voicesLabel,
    voicesHeading,
    voicesCopy,
    // power,
    powerHeading,
    powerSideScroll,
    // philanthropy,
    philanthropyLabel,
    philanthropyHeading,
    philanthropyDescriptor,
    // feminism,
    feminismHeading,
    feminismSideScroll,
  } = entry;
  return {
    meta: {
      title,
    },
    title,
    intro: { heading: introHeading, description: introDescriptor },
    grantmaking: {
      label: grantmakingLabel,
      heading: grantmakingHeading,
      description: grantmakingDescriptor,
    },
    world: {
      heading: wordlHeading,
      copy: worldCopy,
      image: resolveImage(worldImage),
      stats: worldStats,
    },
    map: {
      label: mapLabel,
      heading: mapHeading,
      subheading: mapSubheading,
      copy: mapCopy,
    },
    activate: {
      label: activateLabel,
      heading: activateHeading,
      copy: activateCopy,
    },
    funds: {
      heading: fundsHeading,
      sideScroll: resolveSideScroll(fundsSideScroll),
    },
    stories: {
      label: storiesLabel,
      heading: storiesHeading,
      description: storiesDescriptor,
    },
    values: {
      heading: valuesHeading,
      image: resolveImage(valuesImage),
      stories: stories.map(resolveStory),
    },
    voices: {
      label: voicesLabel,
      heading: voicesHeading,
      copy: voicesCopy,
    },
    power: {
      heading: powerHeading,
      sideScroll: resolveSideScroll(powerSideScroll),
    },
    philanthropy: {
      label: philanthropyLabel,
      heading: philanthropyHeading,
      description: philanthropyDescriptor,
    },
    feminism: {
      heading: feminismHeading,
      sideScroll: resolveSideScroll(feminismSideScroll),
    },
  };
};

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  return <HeadTemplate {...rest} data={resolvedData} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="CanYouMoveItQuery"
    {...props}
  />
);

export default Page;
